<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>환전</sub-title>

        <div class="main_panel">

          <!--                    <apply-buttons-comp></apply-buttons-comp>-->
          <div class="re_info" v-html="infotext"></div>

          <div class="rolling_info">
            <div class="rolling_detail">
              <div class="rolling_title">
                스포츠 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.sports }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                미니게임 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.minigame }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                카지노 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.honorcasinototalpercent }} %
              </div>
            </div>
            <div class="rolling_detail">
              <div class="rolling_title">
                홀덤 롤링
              </div>
              <div class="rolling_cont">
                {{ rolling.reachcasinototalpercent }} %
              </div>
            </div>
          </div>

          <div style="text-align: center;display: flex;align-items: center;justify-content: center;margin-bottom: 5px;color: red">
            <p>보너스 포인트는 현재 롤링 포함되지 않습니다. 포함하여 롤링 완료 후 환전가능합니다</p>
          </div>

          <div class="re_cont">
            <div class="c1">
              예금명
            </div>
            <div class="c2" style="">
              <span>{{ $store.state.userInfo.beneficiary }}</span>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              출금은행
            </div>
            <div class="c2" style="">
              <span>{{ $store.state.userInfo.bank }}</span>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              계좌번호
            </div>
            <div class="c2" style="">
              <span>{{ $store.state.userInfo.acNo }}</span>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              전화번호
            </div>
            <div class="c2" style="">
              <span>{{ $store.state.userInfo.phone }}</span>
              <span style="font-size: 10px;color: #fc6868"> (연락처가 틀리거나 바뀌신분은 고객센터로 변경된 전화번호를 남겨주시기 바랍니다.)</span>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              금액
            </div>
            <div class="c2" style="">
              <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                <input type="text" v-model="applyAmount" @input="setComma" class="re_input" placeholder="환전금액"
                       style=" color: red">
                <button type="button" class="btn-re" @click="changeMoney(0)"
                        style="width: 130px;margin-left: 6px">정정하기
                </button>
              </div>
              <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0;flex-wrap: wrap">
                <button type="button" class="btn-re" @click="changeMoney(10000)">
                  1만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(50000)">
                  5만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(100000)">
                  10만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(500000)">
                  50만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(1000000)">
                  100만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(5000000)">
                  500만원
                </button>
              </div>
            </div>
          </div>
          <!--                    <div style="width: 100%;text-align: center;line-height: 40px;font-weight: bold;color: yellow">
                                  샘플서버입니다. 절대 <span style="color: #ff4d4d;font-size: 19px">`입금`</span> 하지마세요!!!
                              </div>-->
          <div class="rec_buttons" style="">
            <button class="b2" @click="exchange">환전요청
            </button>
          </div>
        </div>
        <div class="re_history">
          <table class="utable" cellpadding="0" cellspacing="0" border="0">
            <colgroup>
              <col width="27%">
              <col width="27%">
              <col width="27%">
              <col width="15%">
            </colgroup>
            <thead>
            <tr>
              <th>일자</th>
              <th>금액</th>
              <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                       aria-hidden="true"></i>
              </th>
              <th>-</th>
            </tr>
            <tr v-for="(item,indx) in exchangeList" :key="indx">
              <td>{{ item.createTime|datef('MM/DD HH:mm') }}</td>
              <td style="color: yellow">{{ item.amount|comma }}</td>
              <td>
                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">환전중</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE" style="color: #35c82d">완료</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                      style="color: red">환전취소</span>
              </td>
              <td>
                  <span
                      style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                      v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

              </td>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>


</template>

<script>
import SubTitle from "../../components/SubTitle";
import RightBarComp from "../../components/RightBarComp";
import {
  deleteApply,
  exchangeCash,
  getApplyCashList,
  getinfotext,
  getMyRolling,
  rechargeCash
} from "../../network/userRequest";
import sportsConst from "../../common/sportsConst";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import TopbarComp from "../../components/TopbarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import {getAccountBalance, subjectMemberCasinoCash} from "../../network/casinoRequest";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import ApplyButtonsComp from "../../components/ApplyButtonsComp";

export default {
  name: "Exchange",
  mixins: [postionMixin],
  components: {
    ApplyButtonsComp,
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SportsLeftBarComp,
    RightBarBannerComp, FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
  },
  data() {
    return {
      sportsConst,
      infotext: null,
      applyCash: {
        amount: 0,
        extractPasswd: ''
      },
      applyAmount: '',
      search: {applyType: sportsConst.APPLY_TYPE_EXCHANGE},
      exchangeList: [],
      casinocash: 0,
      position: "출금",
      rolling: {
        "reachcasino": 0,
        "minigame": 0,
        "honorcasino": 0,
        "total": 0,
        "sports": 0,
        "tgame": 0,
        "tcasino": 0,
        "casinoapply": 0,
        "tslot": 0
      }
    }
  },
  methods: {
    setComma() {
      if (this.applyAmount == '') {
        this.applyAmount = ''
      } else {
        this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
      }
    },
    changeMoney(cash) {
      if (cash === 0) {
        this.applyAmount = '';
      } else {
        let c = this.applyAmount == '' ? 0 : this.applyAmount.replaceAll(',', '')
        this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

      }
    },
    exchange() {
      this.applyCash.amount = this.applyAmount.replaceAll(',', '');
      this.$swal({
        title: this.applyCash.amount + '원 환전신청 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          exchangeCash(this.applyCash).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$swal({
                title: '환전신청성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.applyAmount = ''
              this.applyCash.amount = 0
              this.$store.dispatch('actionUserInfo')
              this.initExchangeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    change2sportsmoney() {
      this.$swal({
        title: '스포츠머니로 전환 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          subjectMemberCasinoCash().then((res) => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            this.$swal({
              title: '전환성공',
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true
            })
            this.casinocash = 0;
            this.$store.dispatch('actionUserInfo')
            setTimeout(() => {
              this.getCasinoBalance();
            }, 1300)
          })
        }
      })

    },
    initExchangeList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getApplyCashList(this.search).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.exchangeList = res.data.data
        }
      })
    },
    refreshData() {
      this.initExchangeList()
      this.$bus.$emit('applyRequestSuccess')
    },
    deleteById(id) {
      this.$swal({
        title: '삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteApply(id).then(res => {
            if (res.data.success) {
              this.initExchangeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    getCasinoBalance() {
      getAccountBalance().then(res => {
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    getInfoText() {
      getinfotext().then(res => {
        this.infotext = res.data.data.exchangeText
      })
    }
  },
  created() {
    this.getInfoText()
    this.initExchangeList()
    getMyRolling().then(res => {
      if (res.data.success) {
        this.rolling = res.data.data;
      }
    })
  },
  mounted() {
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");
@import url("../../assets/css/reex.css");

.re_info {
  width: 100% !important;
}
</style>